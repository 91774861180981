/* ./styles/globals.css */

/* Tailwindcss */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");

/* Custom tailwind class */
@layer components {
  .link {
    @apply cursor-pointer  hover:text-themeone-dark active:text-themeone-dark;
  }
  .button {
    @apply cursor-pointer rounded text-center p-2 text-sm   text-white  bg-gradient-to-b from-themeone-light to-themeone-dark border border-themeone-light  focus:outline-none focus:ring-2 focus:ring-themeone-dark active:from-themeone-dark;
  }
  .button-ghost{
    @apply cursor-pointer rounded text-center p-2 text-sm text-themeone-dark focus:outline-none border-2 border-themeone-dark hover:shadow-md focus:shadow-md active:shadow-md;
  }
  .button-red {
    @apply cursor-pointer rounded text-center p-2 text-sm  text-white  bg-gradient-to-b from-themeone-light to-themeone-dark border-themeone-light  focus:outline-none focus:ring-2 focus:ring-themeone-dark active:from-themeone-dark;
  }
  .dropDownOption {
    @apply w-full cursor-pointer hover:bg-themeone-light py-2 px-3;
  }
  .dashboard-link {
    @apply link rounded border px-4 py-1 hover:bg-themeone-light hover:text-white transition-all duration-200 text-sm;
  }
  .heading{
    @apply lg:text-sub text-center font-medium sm:text-3xl text-2xl ;
  }
  .circle {
    @apply bg-gradient-to-b from-white via-themeone-light to-themeone-dark;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}
input[type="radio"]:checked + span {
  display: block;
}
.heading::after {
    display: block;
    height: 2px;
    background-color: #ab3c2a;
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
}
html,
body {
  font-family: 'Inter', 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1f2937;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
}
.main_heading{
  min-width: 400px;
}

.landingFull {
  margin-top: -9rem;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../public/img/coming-soon.jpg');
  background-size: cover;
  background-position: center center;
}

.heightFix,
.heightFixAdmin {
  min-height: calc(100vh - 150px);
  height: 100%;
}

.loader svg {
  width: 150px;
  height: 150px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}
.table_col_img {
  min-width: 40px;
}

.table_col {
  min-width: 120px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.glassmorphism {
  background-color: white;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.layout {
  min-width: 320px;
}
.sideBarMenu {
  min-width: 250px;
}

.layout .Toastify__progress-bar--default {
  background: #ab3c2a;
  background: linear-gradient(#ab3c2a, #792a1e) !important;
}
.circle {
  height: 480px;
  width: 480px;
  border-radius: 50%;
  display: inline-block;
}

@media only screen and (max-width: 780px) {
  .loader svg {
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 780px) {
  .heightFix {
    min-height: calc(100vh - 200px);
    height: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .main_heading{
    min-width:auto;
  }
}
